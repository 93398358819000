*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  min-height: 100%;
  position: relative;
}

/* Custom properties */
:root {
  --ff-primary: "Poppins", sans-serif;
  --ff-secondary: "Roboto", sans-serif;
  --fw-reg: 300;
  --fw-bold: 900;
  --nav-background: #b60389;
  --clr-background: #f3f3f3;
  --clr-dark: #303030;
  --clr-footer: #cebfbf;
  --clr-socials: rgb(168, 116, 155);
  --fs-h1: 2.5rem;
  --fs-h2: 2.1rem;
  --fs-h3: 1.5rem;
  --fs-h4: 1.2rem;
  --fs-body: 1.025rem;
}

/* @font-face {
  font-family: "Abelonefont";
  src: local("Abelone-FREE"), url('../fonts/Abelone-FREE.otf') format("opentype");
} */

.App {
  margin: 0 auto;
  min-height: 100%;
  max-width: 100%;
  background-color: var(--clr-background);
}

body {
  color: var(--clr-dark);
  background-color: var(--clr-background);
  font-family: var(--ff-primary);
  font-size: var(--fs-body);
  overflow: auto;
  line-height: 1.6;
  margin-bottom: 2rem;
  overflow-y: scroll;
}

/* Homepage section */
.homepage-container {
  max-width: 100%;
  max-height: 100%;
  text-align: center;
}

.heropicture {
  max-width: 35%;
  margin-top: 7em;
  min-width: 14em;
}

.celebrate {
  max-width: 15%;
  transform: rotate(30deg);
  position: absolute;
  top: 20;
  right: 5;
  cursor: pointer;
  height: auto;
}

.headergroup {
  margin-top: 2em;
  margin-bottom: 4em;
}

.headergroup__h1 {
  /* font-family: "Abelonefont", sans-serif; */
  font-size: 3.5rem;
  font-weight: 700;
  
}

h1 {
  font-size: var(--fs-h1);
  text-align: center;
}

h2 {
  font-size: var(--fs-h2);
  text-align: center;
}

h3 {
  font-size: var(--fs-h3);
  text-align: center;
}

h4 {
  font-size: var(--fs-h4);
  text-align: center;
}

p {
  margin: 0 auto;
  text-align: center;
  font-size: var(--fs-body);
}

.neonline {
  width: 35vw;
  height: 8vh;
}

/* 404 page */

.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-found h1 {
  font-size: 4rem;
  font-weight: 900;
  padding-bottom: 0;

}

.not-found h2 {
  margin-top: 0rem;
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 2rem;
}

/* About section css */
.textcontainer {
  max-height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0em 2em;
  margin: 0 auto;
  line-height: 1.4;
  max-width: 900px;
}

.what-header {
  transition: 700ms ease;
  font-variation-settings: "wght" 311;
  letter-spacing: -2px;
}

.what-header:hover {
  font-variation-settings: "wght" 900;
  letter-spacing: 0px;
  cursor: default;
}

.aboutmaincontainer p {
  max-width: 90%;
  margin: 0 auto;
  padding: 1em 0;
}

.signature {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.signature img {
  width: 400px;
  height: 100px;
}

/* People section */
.allavatarcontainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 20px 20px;
  max-width: 70%;
  align-items: center;
  margin: 0 auto;
}

.labels {
  padding: 10px 10px;
  background-color: rgb(107, 167, 167);
  border-radius: 10px;
  box-shadow: 10px 10px 15px -7px rgb(79, 126, 119);
}

.peopletextcontainer {
  padding: 1em 0.5em;
  align-items: center;
}

.peoplemaincontainer p {
  max-width: 70%;
  padding: 1em 0;
}

.personcontainer {
  padding: 1.7em;
}

.peoplemaincontainer {
  text-align: center;
}

/* Progress Bar */

.progress {
  height: 20;
  width: 300px;
  background-color: #f2d5f3;
  border-radius: 5%;
  margin: 1rem;
}

.progress-bar {
  height: 100%;
  background-color: rgb(50, 179, 50);
  border-radius: inherit;
  text-align: right;
  padding-right: 0.2rem;
  transition: 'width 1s ease-in-out'
}

.progress-bar__label {
  padding: 5;
  color: white;
  font-weight: bold;
}



/* Calendar section */

.allcalendar {
  /* max-height: 100%; */
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: row;
}
.calendar-detailbox {
  padding: 5px 15px;
  min-width: 300px;
  max-width: 40%;
  margin: 0 auto;
  max-height: fit-content;
}

.calendar--eventinfo {
  padding: 1em 0.5em;
  display: grid;
  background: rgb(215, 106, 195);
  background: radial-gradient(
    circle,
    rgba(215, 106, 195, 1) 0%,
    rgba(89, 103, 203, 1) 100%
  );
  border-radius: 1rem;
  box-shadow: 10px 10px 15px -7px rgb(207, 224, 109);
}

.calendar--eventinfo > p,
h4 {
  margin: 0;
}

.eventinfo-diclaimer {
  padding-top: 2em;
}

.calendarHolder {
  width: 30%;
  padding: 5em 1.2em;
  min-width: 300px;
  margin: 0 auto;
}

.highlight {
  background: rgba(102, 13, 143, 0.442) !important;
  border-radius: 80px;
  width: 0.5rem;
  height: 2rem;
}

.unicorn {
  margin-top: 6vh;
}

.calendarpage h4,
h1 {
  padding: 1em 2em;
}

/*Gallery*/

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Contact form */
.thewholecontact {
  /* max-width: 100%; */
  text-align: center;
}

.form-container {
  left: 15%;
  max-width: 70%;
  font-family: var(--ff-primary);
  padding: 0em 2em;
  box-sizing: border-box;
  margin: 0 auto;
}

.contact-form {
  top: 10%;
  left: 10%;
}

.contact-form input[type="text"],
.contact-form input[type="email"] {
  color: black;
  padding-left: 0.4rem;
  font-size: var(--fs-body);
  width: 80%;
  margin-top: 2rem;
}

.contact-form textarea {
  width: 80%;
  margin-top: 2rem;
  border: none;
  border-bottom: 2px solid black;
  font-size: var(--fs-body);
  padding-left: 0.4rem;
  padding-top: 0.4rem;
  resize: vertical;
}

.submit-button {
  border: 2px solid rgb(57, 146, 146);
  padding: 1rem;
  text-align: center;
  background-color: #ecdfdf;
  cursor: pointer;
  width: 80%;
  font-size: var(--fs-body);
  font-weight: 600;
  color: #341566;
  border-radius: 0.2rem;
}

.submit-button:hover {
  opacity: 0.6;
  background-color: rgb(95, 170, 204);
}

.frc-captcha {
  margin-left: 6vw;
  margin-block: 1rem;
}

/* Card */
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;
  margin-bottom: 1.5rem;
}

.card {
  margin: 1rem;
  flex-basis: 45%;
  padding: 1.5rem;
  color: inherit;
  text-decoration: none;
  border: 2px solid #d714e9;
  background-color: #f2d5f3;
  box-shadow: 10px 10px 15px -7px #7e7b7e;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
}

.card:hover {
  border-color: rgb(0, 217, 255);
  user-select: none;
}

.card h3 {
  text-align: left;
}

.card p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

@media (max-width: 800px) {
  :root {
    --fs-h1: 1.7rem;
    --fs-h2: 1.4rem;
    --fs-h3: 1.1rem;
    --fs-h4: 0.89rem;
    --fs-body: 1rem;
  }

  .headergroup__h1 {
    font-size: 2.5rem;
  }

  h2 {
    padding: 0 1em;
  }
}

@media (max-width: 480px) {
  .recaptchacontainer {
    margin: 0 auto;
    margin-top: 3vh;
    transform: scale(0.67);
    -webkit-transform: scale(0.67);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
  .form-container {
    max-width: 90%;
    left: 5%;
  }
}

@media (max-width: 290px) {
  .celebrate {
    display: none;
  }
}
