.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  top: 0;
  /* background-color: var(--nav-background); */
  background: linear-gradient(333deg, rgba(203,0,221,1) 0%, rgba(47,128,219,1) 84%);
  /* background: linear-gradient(
    90deg,
    rgba(81, 27, 219, 1) 0%,
    rgba(170, 18, 172, 1) 37%,
    rgba(255, 0, 164, 1) 100%
  ); */
  text-decoration: none;
  letter-spacing: 0.5px;
  margin-bottom: 2em;
  font-weight: 900;
  overflow: hidden;
  height: 5rem;
}

nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.login-link {
  margin-right: 1rem;
}

.login-link:hover {
  color: #59f780;
}

.slider ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  width: calc(100% - 130px);
}

.slider ul li:not(:first-child) {
  display: flex;
  margin-block: 0.3rem;
  margin-inline: 0.5rem;
  padding: 0 5px;
  list-style: none;
}

.slider ul li a {
  text-decoration: none;
  text-align: center;
  /* color: rgb(244, 245, 245); */
  color: violet;
  font-size: 1rem;
  overflow: hidden;
  background: linear-gradient(
    to left,
    #fdfeff,
    #f2f7fa 50%,
    rgb(65, 225, 65) 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
}

.slider ul li a:hover {
  color: #59f780;
  background-position: 0 100%;
}

.slider ul li:first-child {
  margin-right: 2rem;
}

.nav-logging #logout {
  padding: 0.35rem;
  background-color: #59f780;
  border-radius: 0.5rem;
  font-family: "Poppins", sans-serif;
  border: 2px solid #59f780;
  margin-right: 1rem;
}

#logout:hover {
  background-color: rgb(59, 223, 223);
  cursor: pointer;
}

.menu-icon .menu {
  display: none;
  color: white;
  font-size: 50px;
  cursor: pointer;
}

.closed .close {
  display: none;
}

.navlink {
  display: inline-block;
  position: relative;
  color: #59f780;
}

/* need to check */
/* .navbar li .navlink.active {
  color: rgb(83, 255, 77) !important;
  background-color: aqua !important;
} */

.navlink::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #59f780;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.navlink:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navlink::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 3px;
  background-color: rgb(98, 89, 179);
  transition: width 0.25s ease-out;
}

.navbar > .lnightlogo {
  cursor: pointer;
  height: 4rem;
  display: flex;
  left: 0;
}

footer {
  background: var(--clr-footer);
  height: 7rem;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 100%;
  bottom: 0;
  left: 0;
}

.footer__socials {
  color: var(--clr-socials);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer__copyright {
  color: rgb(153, 131, 148);
  font-family: var(--ff-secondary);
  font-size: small;
  cursor: default;
  text-align: center;
  margin: 0;
}

.footer__links {
  font-size: 2rem;
  padding: 0 0.3em;
  color: inherit;
}

.footer__links:hover {
  color: rgb(173, 95, 247);
}

.fa-clickable {
  cursor: pointer;
  outline: none;
}



.legal-stuff {
  display: flex;
  gap: 0.5rem;
  color: #998394;
  margin-bottom: 0;
}

.legal-stuff a {
  color: inherit;
  font-size: small;
}

/* Privacy policy */
.privacy-policy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 4rem 2rem 4rem;
}

.privacy-policy-container h1 {
  color: #ac24d6;
}

.privacy-policy-container h2 {
  margin: 1rem;
  color: #d624c7;
}

.privacy-policy-container p {
  text-align: left;
  margin: 0.5rem;
}

.privacy-policy-container ul {
  margin: 1rem;
}

@media (max-width: 900px) {
  .navbar {
    justify-content: space-between;
  }

  .slider {
    position: fixed;
    min-width: 100%;
    top: -100%;
    background-color: var(--nav-background);
    transition: 0.5s ease;
    z-index: 10;
  }

  .slider.active {
    top: 0;
    transition: 0.5s ease;
  }

  .slider ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    margin-top: 3rem;
    margin-left: 2rem;
  }

  .slider ul li:last-child {
    padding-bottom: 1.5rem;
  }

  .slider ul li a {
    text-decoration: none;
    padding: 0 10px;
    text-align: center;
    color: rgb(205, 187, 235);
    font-size: large;
  }

  .login-link {
    margin-top: 16rem;
  }

  .menu-icon .menu {
    display: block;
    color: white;
    font-size: 50px;
    cursor: pointer;
  }

  .closed .close {
    display: block;
    font-size: 30px;
    margin: 0.5rem;
  }
}
