.Gallerypicscontainer {
  margin: 0 auto;
  margin-top: 1rem;
  max-width: 90%;
}

.imageUpload {
  padding: 20px 10px;
  align-items: center;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.imageUpload > form > input {
  padding: 0.3rem;
  margin: 0.5rem;
  background-color: lightgray;
  border-radius: 0.2rem;
  border: none;
}

.imageUpload > form > button {
  padding: 0.3rem;
}
